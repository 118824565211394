import '../sass/video.scss';

const videoLayerHandler = (video_layers, source) => {
    video_layers.forEach(function (video_layer) {
        video_layer.style.display = 'block';
        video_layer.querySelector('iframe').setAttribute('src', source);
    });
};

let youtubeIframeAPIloaded = false;
const loadYoutubeIframeAPI = () => {
    if (youtubeIframeAPIloaded) {
        return;
    }

    youtubeIframeAPIloaded = true;

    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};


const videoTriggersHandler = (video_triggers, video_wrapper) => {
    video_triggers.forEach(function (video_trigger) {
        const accept_buttons = video_trigger.querySelectorAll('.play-button');
        accept_buttons.forEach(function (accept_button) {
            accept_button.addEventListener('click', function () {
                const _trigger = this.parentElement;
                const data_type = _trigger.getAttribute('data-type');
                if (data_type === 'youtube') {
                    loadYoutubeIframeAPI();
                }
                _trigger.style.display = 'none';

                // const data_source = _trigger.getAttribute('data-source');
                const source = _trigger.dataset.url;

                const video_layers = _trigger.parentElement.querySelectorAll('.video_layer');

                videoLayerHandler(video_layers, source);
                video_wrapper.style.backgroundImage = '';
                video_wrapper.style.height = 'auto';
                video_wrapper.classList.add('zentis-video-accepted');

            }, false);
        });
    });
};

const lazyLoad = () => {
    const videos = [].slice.call(document.querySelectorAll('video.lazy-load'));

    if ('IntersectionObserver' in window) {
        const videoObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (video) {
                if (video.isIntersecting) {
                    for (const source in video.target.children) {
                        const videoSource = video.target.children[source];
                        if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
                            videoSource.src = videoSource.dataset.src;
                        }
                    }

                    video.target.load();
                    video.target.classList.remove('lazy-load');
                    videoObserver.unobserve(video.target);
                }
            });
        });

        videos.forEach(function (video) {
            videoObserver.observe(video);
        });
    }
};

const videoHandler = () => {
    const video_wrapper = document.querySelectorAll('.zentis-external-video');
    if (typeof video_wrapper !== 'undefined' && video_wrapper !== null) {
        video_wrapper.forEach(function (video_wrapper) {
            const video_triggers = video_wrapper.querySelectorAll('.video_trigger');
            if (typeof video_triggers !== 'undefined' && video_triggers !== null) {
                videoTriggersHandler(video_triggers, video_wrapper);
            }
        });
    }
};


/* Internal Video state handling */

// auto pause/play on intersection in view
const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            //this only works if user already interacted with the video, so we wrap this in try/catch
            if (entry.target.paused && entry.target.getAttribute('data-paused-through-observer') === '1') {
                const playPromise = entry.target.play();
                playPromise.catch(e => {
                    //nothing
                });

                entry.target.removeAttribute('data-paused-through-observer');
            }
        } else {
            if (!entry.target.paused) {
                entry.target.setAttribute('data-paused-through-observer', '1');
                entry.target.pause();
            }
        }
    });
});

const internalVideoWraps = document.querySelectorAll('.zentis-internal-video-wrap');

[...internalVideoWraps].forEach(internalVideoWrap => {
   const playButton = internalVideoWrap.querySelector('.play-button');
   const video = internalVideoWrap.querySelector('.zentis-internal-video');

    // ios safari special:
    // hide controls as long as the video is initially not autoloaded and the custom play-button is visible
    // this prevents the default play button is visible on ios/safari.
    // as it works without negative results also for other devices/browser we not build it with ios/safari condition
    const hasInitialControls = video.controls;
    if (!video.autoplay && video.paused && video.controls) {
        video.controls = false;
    }
    //end special ios safari code

    if (playButton) {
        playButton.addEventListener('click', () => {
            video.play();

            //activate initial controls state on play button click
            if (hasInitialControls) {
                video.controls = true;
            }
        });
    }
    video.addEventListener('play', () => {
        internalVideoWrap.setAttribute('data-state', 'play');
    });
    video.addEventListener('pause', () => {
        internalVideoWrap.setAttribute('data-state', 'pause');
    });

    observer.observe(video);
});


document.addEventListener('DOMContentLoaded', function () {
    videoHandler();
    lazyLoad();
});
